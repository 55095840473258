<template>
  <div>
    <div v-if="!customVisible && !staticVisible">
      <div class="top">
        <el-page-header @back="goBack" :content="item.nick_name + '的模板消息'">
        </el-page-header>
      </div>
      <div class="main">
        <!-- <div class="title">
          应用公众号：公众号1
        </div> -->
        <!-- <div class="tabs">
          <el-tabs v-model="type">
            <el-tab-pane label="营销场景消息" name="marketing"></el-tab-pane>
            <el-tab-pane label="常规消息" name="routine"></el-tab-pane>
          </el-tabs>
        </div> -->
        <template v-if="type === 'marketing'">
        <div class="box">
          <el-button type="primary" icon="el-icon-plus" class="btn3" size="small" @click="handleAddCustom">新增自定义消息</el-button>
          <div class="line"></div>
          <el-form :model="form" :inline="true" class="form">
            <el-form-item label="消息名称">
              <el-input v-model="form.title" placeholder="请输入消息名称" clearable="" />
            </el-form-item>
            <!-- <el-form-item label="消息类型">
              <el-select v-model="form.type">
                <el-option label="全部类型" value="0"></el-option>
                <template v-for="(value, key) in __messageType__">
                  <el-option :label="value" :value="key" :key="key"></el-option>
                </template>
              </el-select>
            </el-form-item> -->
            <el-form-item label="消息状态">
              <el-select v-model="form.status">
                <el-option label="全部状态" value=""></el-option>
                <el-option label="启用" :value="1"></el-option>
                <el-option label="停用" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="创建时间">
              <el-date-picker
                  v-model="form.created_time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button type="primary" plain @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
          <div class="table">
            <el-table
              :row-class-name="handleIndex"
              style="border-top: 1px solid #ddd;"
              border
              :data="tableData.list"
              stripe
              v-loading="loading"
              height="600"
              @row-dblclick="handleDalclick"
            >
              <template v-for="(item, index) in columnList2">
                <el-table-column
                  align="center"
                  :key="index"
                  :prop="item.field_alias"
                  :label="item.field_text"
                  :width="item.t_width"
                  :filters="item.filed_status"
                >
                  <template slot-scope="scope">
                    <template v-if="item.field_alias === 'status'">
                      <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" @change="handleChange($event, scope.row)"></el-switch>
                    </template>
                    <template v-else-if="item.field_alias === 'type'">
                      {{__messageType__[scope.row.type]}}
                    </template>
                     <template v-else-if="item.field_alias === 'push_result'">
                      <span style="color: #67C23A">成功：{{scope.row.push_success_num}}条</span>
                      <span style="color: #F56C6C; margin-left: 10px;">失败：{{scope.row.push_error_num}}条</span>
                    </template>
                    <template v-else>
                      {{
                        scope.row[item.field_alias]
                          ? scope.row[item.field_alias]
                          : "--"
                      }}
                    </template>
                  </template>
                </el-table-column>
              </template>
              <el-table-column align="center" field_alias="operation" label="操作">
                <template slot-scope="scope">
                  <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
                  <el-button type="text" style="color: #F56C6C;" @click="handleDele(scope.row)" v-if="scope.row.type === 1">删除</el-button>
                  <el-button type="text" @click="toStatic(scope.row)">推送统计</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class='pagina'>
            <el-pagination
              background
              @size-change="handleSizeChange"
              layout='total, sizes, prev, pager, next, jumper'
              :total='tableData.total'
              :page-size='limit'
              :page-sizes="$store.state.pageSizes"
              @current-change='handleCurrentChange'
            ></el-pagination>
          </div>
        </template>
        <template v-if="type === 'routine'">
        <div class="box">
          <el-form :model="form2" :inline="true" class="form">
            <!-- <el-form-item label="消息类型">
              <el-select v-model="form.type">
                <el-option label="全部类型" value="0"></el-option>
                <template v-for="(value, key) in __messageType__">
                  <el-option :label="value" :value="key" :key="key"></el-option>
                </template>
              </el-select>
            </el-form-item> -->
            <el-form-item label="消息状态">
              <el-select v-model="form2.status">
                <el-option label="全部状态" value=""></el-option>
                <el-option label="启用" :value="1"></el-option>
                <el-option label="停用" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="创建时间">
              <el-date-picker
                  v-model="form2.created_time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch2">查询</el-button>
              <el-button type="primary" plain @click="handleReset2">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
          <div class="table">
            <el-table
              :row-class-name="handleIndex"
              style="border-top: 1px solid #ddd;"
              border
              :data="tableData2.list"
              stripe
              v-loading="loading2"
              height="600"
              @row-dblclick="handleDalclick2"
            >
              <template v-for="(item, index) in columnList2">
                <el-table-column
                  align="center"
                  :key="index"
                  :prop="item.field_alias"
                  :label="item.field_text"
                  :width="item.t_width"
                  :filters="item.filed_status"
                >
                  <template slot-scope="scope">
                    <template v-if="item.field_alias === 'status'">
                      <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" @change="handleChange($event, scope.row)"></el-switch>
                    </template>
                    <template v-else-if="item.field_alias === 'type'">
                      {{__messageType__[scope.row.type]}}
                    </template>
                    <template v-else-if="item.field_alias === 'push_result'">
                      <span style="color: #67C23A">成功：{{scope.row.push_success_num}}条</span>
                      <span style="color: #F56C6C; margin-left: 10px;">失败：{{scope.row.push_error_num}}条</span>
                    </template>
                    <template v-else>
                      {{
                        scope.row[item.field_alias]
                          ? scope.row[item.field_alias]
                          : "--"
                      }}
                    </template>
                  </template>
                </el-table-column>
              </template>
              <el-table-column align="center" field_alias="operation" label="操作">
                <template slot-scope="scope">
                  <el-button type="text"  @click="handleEdit(scope.row)">编辑</el-button>
                  <el-button type="text" @click="toStatic(scope.row)">推送统计</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class='pagina'>
            <el-pagination
              background
              @size-change="handleSizeChange2"
              layout='total, sizes, prev, pager, next, jumper'
              :total='tableData2.total'
              :page-size='limit2'
              :page-sizes="$store.state.pageSizes"
              @current-change='handleCurrentChange2'
            ></el-pagination>
          </div>
        </template>
      </div>
    </div>
    <Custom v-if="customVisible" @onBack="handleBack" :row="currRow" :item="item" />
    <Static v-if="staticVisible" @onBack="handleBack" :row="currRow"  />
  </div>
</template>

<script>
import Custom from './Custom.vue';
import Static from './Static.vue';
import { getWeChatMessageList, changeSwitchReq, deleWeChatMessageReq } from '@/api/marketing/message';
import __messageType__ from '@/utils/messageType.js';

export default {
  data() {
    this.__messageType__ = __messageType__;
    return {
      __messageType__: {},
      staticVisible: false,
      currRow: {},
      customVisible: false,
      page2: 1,
      limit2: this.$store.state.pageSizes[0],
      form2: {
        type: '0',
        created_time: [],
        status: '',
      },
      page: 1,
      limit: this.$store.state.pageSizes[0],
      form: {
        type: '0',
        created_time: [],
        status: '',
      },
      type: 'marketing',
      loading: false,
      loading2: false,
      columnList: [
        { field_text: "序号", field_alias: "index", t_width: 100 },
        { field_text: "消息名称", field_alias: "title" },
        { field_text: "消息类型", field_alias: "type" },
        { field_text: "状态", field_alias: "status" },
        { field_text: "修改人", field_alias: "update_client_user" },
        { field_text: "创建时间", field_alias: "create_time"},
        { field_text: "最后修改时间", field_alias: "update_time"},
        { field_text: "最后推送时间", field_alias: "push_create_time"},
        { field_text: "推送结果", field_alias: "push_result"},
      ],
      columnList2: [
        { field_text: "序号", field_alias: "index", t_width: 100 },
        { field_text: "消息类型", field_alias: "type" },
        { field_text: "模板标题", field_alias: "template_title" },
        { field_text: "模板ID", field_alias: "template_code", t_width: 250 },
        { field_text: "状态", field_alias: "status" },
        { field_text: "修改人", field_alias: "update_client_user" },
        { field_text: "创建时间", field_alias: "create_time"},
        { field_text: "最后修改时间", field_alias: "update_time"},
        { field_text: "最后推送时间", field_alias: "push_create_time"},
        { field_text: "推送结果", field_alias: "push_result"},
      ],
      tableData2: {
        list: [],
        total: 0,
      },
      tableData: {
        list: [],
        total: 0,
      },
    };
  },
  created() {
    this.getList();
    this.getList2();
  },
  props: {
    item: {
    type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    Custom,
    Static,
  },
  methods: {
    // 去推送
    toStatic(row) {
      this.currRow = row;
      this.staticVisible = true;
    },
    // 新增消息
    handleAddCustom() {
      this.customVisible = true;
      this.currRow = {
        type: 1,
        is_marketing: 1,
      };
    },
    // 编辑
    handleEdit(row) {
      this.customVisible = true;
      this.currRow = row;
      console.log(row);
    },
    // 删除
    handleDele(row) {
      this.$confirm('确定要删除该模板消息吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            deleWeChatMessageReq({
              wechat_message_scenario_id: row.wechat_message_scenario_id,
            })
              .then((res) => {
                if (res.code === 1) {
                  this.getList();
                }
              })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          })
    },
    // 重置
    handleReset2() {
      this.page2 = 1;
      this.form2 = {
        created_time: [],
        status: '',
      };
      this.getList2();
    },
    // 重置
    handleReset() {
      this.page = 1;
      this.form = {
        created_time: [],
        status: '',
      };
      this.getList();
    },
    handleChange(val, row) {
      changeSwitchReq({
        wechat_message_scenario_id: row.wechat_message_scenario_id,
        status: val,
      })
        .then((res) => {
          if (res.code === 1) {
            this.$message.success('更新状态成功');
          }
        })
        .catch(() => {
          if (val) {
            row.status = 0;
          } else {
            row.status = 1;
          }
        });
    },
    handleIndex({row, rowIndex}) {
      row.index = rowIndex + 1;
    },
    handleSearch2() {
      this.page2 = 1;
      this.getList2();
    },
    handleSearch() {
      this.page = 1;
      this.getList();
    },
    // 获取列表
    getList2() {
      this.loading2 = true;
      getWeChatMessageList({
        wechat_config_id: this.item.wechat_config_id,
        is_marketing: 2,
        ...this.form2,
        start_time: this.form2.created_time[0],
        end_time: this.form2.created_time[1],
        page: this.page2,
        limit: this.limit2,
      })
        .then((res) => {
          this.loading2 = false;
          if (res.code === 1) {
            this.tableData2 = res.data;
          }
        })
        .catch(() => {
          this.loading2 = false;
        })
    },
    // 获取列表
    getList() {
      this.loading = true;
      getWeChatMessageList({
        wechat_config_id: this.item.wechat_config_id,
        is_marketing: 2,
        ...this.form,
        start_time: this.form.created_time[0],
        end_time: this.form.created_time[1],
        page: this.page,
        limit: this.limit,
      })
        .then((res) => {
          this.loading = false;
          if (res.code === 1) {
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        })
    },
    handleBack(getList) {
      this.customVisible = false;
      this.staticVisible = false;
      if (getList) {
        this.getList();
        this.getList2();
      }
    },
    handleCurrentChange2(page) {
      this.page2 = page;
      this.getList2();
    },
    handleSizeChange2(limit) {
      this.limit2 = limit;
      this.getList2();
    },
    // 改变当前页数
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    // 改变条数
    handleSizeChange(limit) {
      this.limit = limit;
      this.getList();
    },
    handleDalclick2(row) {
      this.handleEdit(row);
    },
    handleDalclick(row) {
      this.handleEdit(row);
    },
    goBack() {
      this.$emit('onBack');
    },
  },
}
</script>

<style lang="less" scoped>
.form {
  /deep/ .el-form-item {
    margin-bottom: 0px;
  }
}
.box {
  display: flex;
  height: 64px;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(243,249,255,1) 100%);
  padding-left: 32px;
  display: flex;
  align-items: center;
}
/deep/ .el-tabs__active-bar {
  height: 4px;
  border-radius: 2px;
  width: 18px !important;
  left: 45px;
}
/deep/ .el-tabs__item:hover {
  color: #1991FF;
}
/deep/ .el-tabs__item.is-active {
  color: #1991FF;
}
/deep/ .el-tabs__nav-wrap::after {
  height: 0px;
}
/deep/ .el-tabs__item {
  padding: 0;
  text-align: center;
  width: 116px;
  height: 48px;
  font-size: 16px;
  color: #909399;
}
.tabs {
  background: white;
  height: 48px;
  border-bottom: 1px solid #ddd;
  padding-left: 22px;
}
.main {
  margin-top: 10px;

}
.title {
  background: white;
  height: 48px;
  line-height: 48px;
  text-indent: 32px;
  font-weight: 700;
  font-size: 16px;
  border-bottom: 1px solid #ddd;
  color: #565656;
}
/deep/ .el-page-header__title {
  color: #565656;
}
/deep/ .el-page-header__content {
  font-size: 16px;
  color: #565656;
}
.top {
  background: white;
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 32px;
}
</style>
