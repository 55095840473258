<template>
  <div>
    <div class="top">
      <el-page-header @back="goBack" content="我的公众号模板">
      </el-page-header>
    </div>
    <div class="main">
      <div class="title">
        应用公众号：{{item.nick_name}}
      </div>
      <div class="box">
        <el-button type="primary" class="btn1" @click="handleSynchronous" :loading="saveLoading">同步</el-button>
        <el-button type="primary" class="btn2" @click="handleManage">管理公众号</el-button>
        <div class="tip">
          <i class="el-icon-warning-outline"></i>
          注意：若使用场景消息，需要同步我们的模板，请保证您公众号有足够的消息模板位置
        </div>
      </div>
      <div class="table">
        <el-table
          :row-class-name="handleIndex"
          style="border-top: 1px solid #ddd;"
          border
          :data="tableData"
          stripe
          v-loading="loading"
          height="650"
          @row-dblclick="handleDalclick"
        >
          <template v-for="(item, index) in columnList">
            <el-table-column
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
              :filters="item.filed_status"
            >
              <template slot-scope="scope">
                <template v-if="item.field_alias === 'status'">
                  <span v-if="scope.row.status === 1" style="color: #67C23A;">正常</span>
                  <span v-if="scope.row.status === 0" style="color: #909399;">已失效</span>
                </template>
                <template v-else>
                  {{
                    scope.row[item.field_alias]
                      ? scope.row[item.field_alias]
                      : "--"
                  }}
                </template>
              </template>
            </el-table-column>
          </template>
          <el-table-column align="center" field_alias="operation" label="操作">
            <template slot-scope="scope">
              <el-button type="text" style="color: #F56C6C;" @click="handleDele(scope.row)">移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { getTemplateListReq, deleteTemplateReq, synchronousReq } from '@/api/marketing/message';

export default {
  data() {
    return {
      saveLoading: false,
      loading: false,
      columnList: [
        { field_text: "序号", field_alias: "index", t_width: 100 },
        { field_text: "模板ID", field_alias: "template_id" },
        { field_text: "标题", field_alias: "title" },
        { field_text: "状态", field_alias: "status" },
        { field_text: "最后同步时间", field_alias: "update_time" },
      ],
      tableData: [],
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {
    this.getTemplateList();
    console.log(this.item)
  },
  methods: {
    // 同步
    handleSynchronous() {
      this.$confirm('是否确认同步消息模板', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.saveLoading = true;
          synchronousReq({
            wechat_config_id: this.item.wechat_config_id,
          })
            .then((res) => {
              this.saveLoading = false;
              if (res.code === 1) {
                this.$message.success(res.msg);
                this.getTemplateList();
              }
            })
            .catch(() => {
              this.saveLoading = false;
            })
        })
        .catch(() => {
           this.$message({
            type: 'info',
            message: '已取消同步'
          });
        })
    },
    // 移除
    handleDele(row) {
      this.$confirm('确定要移除该模板吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteTemplateReq({
            wechat_config_id: this.item.wechat_config_id,
            template_id: row.template_id,
          })
            .then((res) => {
              if (res.code === 1) {
                this.getTemplateList();
              }
            });
        })
        .catch(() => {
           this.$message({
            type: 'info',
            message: '已取消移除'
          });
        })
    },
    handleManage() {
      window.open('https://mp.weixin.qq.com/');
    },
    handleIndex({row, rowIndex}) {
      row.index = rowIndex + 1;
    },
    // 获取模板
    getTemplateList() {
      this.loading = true;
      getTemplateListReq({
        wechat_config_id: this.item.wechat_config_id,
      })
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.template;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    goBack() {
      this.$emit('onBack');
    },
    handleDalclick() {},
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-page-header__title {
  color: #565656;
}
/deep/ .el-page-header__content {
  font-size: 16px;
  color: #565656;
}
.box {
  padding-left: 32px;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(243,249,255,1) 100%);
  height: 64px;
  line-height: 64px;
  display: flex;
  align-items: center;
  .tip {
    color: #1991FF;
    margin-left: 34px;
  }
}
.btn1 {
  width: 72px;
  height: 36px;
  line-height: 0;
}
.btn2 {
  width: 108px;
  height: 36px;
  line-height: 0;
}
.title {
  background: white;
  height: 48px;
  line-height: 48px;
  text-indent: 32px;
  font-weight: 700;
  font-size: 16px;
  border-bottom: 1px solid #ddd;
  color: #565656;
}
.top {
  background: white;
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 32px;
}
.main {
  margin-top: 10px;

}
</style>
