<template>
  <div>
    <div class="top">
      <el-page-header @back="goBack" content="推送统计">
      </el-page-header>
    </div>
    <div class="title">
      消息名称：{{row.title}}
    </div>
    <div class="main">
      <div class="head">
        推送数据
      </div>
      <div class="form">
        <el-form :model="form" :inline="true">
          <el-form-item label="统计方式">
            <el-select v-model="form.time_type" @change="handleSelect">
              <el-option label="日统计" value="day"></el-option>
              <el-option label="月统计" value="month"></el-option>
              <el-option label="年统计" value="year"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="统计时间">
            <el-date-picker
              v-if="form.time_type === 'day'"
              :clearable="false"
              v-model="form.time"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
            <el-date-picker
              v-if="form.time_type === 'month'"
              v-model="form.time"
              value-format="yyyy-MM"
              type="monthrange"
              range-separator="至"
              start-placeholder="开始月份"
              end-placeholder="结束月份">
            </el-date-picker>
            <div style="display: flex;" v-if="form.time_type === 'year'">
              <el-date-picker
                style="width: 140px;"
                :clearable="false"
                v-model="form.time[0]"
                value-format="yyyy"
                type="year">
              </el-date-picker>
              <div style="margin: 0 10px;">至</div>
              <el-date-picker
                style="width: 140px;"
                :clearable="false"
                v-model="form.time[1]"
                value-format="yyyy"
                type="year">
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getCoordinatesLog">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="chart">
        <div class="model">
          <div class="box">
            <div class="btn1" @click="handleClick(1)" :class="form.coordinates_type === 1 ? 'btn-active' : ''">推送会员</div>
            <div class="btn2" @click="handleClick(2)" :class="form.coordinates_type === 2 ? 'btn-active' : ''">推送次数</div>
          </div>
          <span style="color: #909399; font-size: 14px;" v-if="form.coordinates_type === 1">推送会员：{{chartData.count}}人</span>
          <span style="color: #909399; font-size: 14px;" v-if="form.coordinates_type === 2">推送次数：{{chartData.count}}次</span>
        </div>
        <div id="container" v-loading="loading"></div>
      </div>
    </div>
    <div class="main">
      <div class="head">
        推送明细<span class="tip">（仅保留最近6个月的推送明细）</span>
      </div>
      <div class="form">
        <el-form :model="form2" :inline="true">
          <el-form-item label="会员名称">
            <el-input v-model="form2.realname" placeholder="请输入会员名称" />
          </el-form-item>
          <el-form-item label="统计时间">
            <el-date-picker
              v-model="form2.time"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="page = 1; getDetailLog();">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="table">
            <el-table
              style="border-top: 1px solid #ddd;"
              border
              :data="tableData.list"
              stripe
              v-loading="loading2"
              height="600"
              :row-class-name="handleIndx"
            >
              <template v-for="(item, index) in columnList">
                <el-table-column
                  align="left"
                  :key="index"
                  :prop="item.field_alias"
                  :label="item.field_text"
                  :width="item.t_width"
                  :filters="item.filed_status"
                >
                  <template slot-scope="scope">
                    <template v-if="item.field_alias === 'avatar'">
                      <img :src="scope.row.avatar" style="width: 48px; height: 48px; border-radius: 50%;" />
                    </template>
                    <template v-else-if="item.field_alias === 'content'">
                      <div>消息模板类型：{{row.title}}</div>
                      <div>消息名称：{{row.template_title}}</div>
                      <div>推送时间：{{scope.row.push_time}}</div>
                      <div>开始语：{{scope.row.data.first.value}}</div>
                      <div>结束语：{{scope.row.data.remark.value}}</div>
                    </template>
                    <template v-else>
                      {{
                        scope.row[item.field_alias]
                          ? scope.row[item.field_alias]
                          : "--"
                      }}
                    </template>
                  </template>
                </el-table-column>
              </template>
            </el-table>
          </div>
          <div class='pagina'>
            <el-pagination
              background
              @size-change="handleSizeChange"
              layout='total, sizes, prev, pager, next, jumper'
              :total='tableData.total'
              :page-size='limit'
              :page-sizes="$store.state.pageSizes"
              @current-change='handleCurrentChange'
            ></el-pagination>
          </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { Area } from '@antv/g2plot';
import { coordinatesLogReq, detailLogReq } from '@/api/marketing/message';
import __messageType__ from '@/utils/messageType.js';
import template from '../template.vue';

export default {
  components: { template },
  data() {
    this.__messageType__ = __messageType__;
    return {
       __messageType__: {},
      loading2: false,
      chartData: {
        list: [],
        count: 0,
      },
      columnList: [
        { field_text: "序号", field_alias: "index", t_width: 100 },
        { field_text: "会员头像", field_alias: "avatar" },
        { field_text: "会员名称", field_alias: "weixin_nickname" },
        { field_text: "推送时间", field_alias: "push_time" },
        { field_text: "推送内容", field_alias: "content" },
      ],
      loading: false,
      page: 1,
      limit: this.$store.state.pageSizes[0],
      tableData: {
        list: [],
        total: 0,
      },
      form2: {
        time: [],
      },
      form: {
        time_type: 'day',
        time: [],
        coordinates_type: 1,
      },
      areaChart: null,
    };
  },
  props: {
    row: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {
    this.getTime();
  },
  mounted() {
    this.getCoordinatesLog();
    this.getDetailLog();
  },
  methods: {
    handleIndx({row, rowIndex}) {
      row.index = rowIndex + 1;
    },
    // 获取推送详情
    getDetailLog() {
      this.loading2 = true;
      detailLogReq({
        wechat_message_scenario_id: this.row.wechat_message_scenario_id,
        realname: this.form2.realname,
        start_time: this.form2.time[0],
        end_time: this.form2.time[1],
        page: this.page,
        limit: this.limit,
      })
        .then((res) => {
          this.loading2 = false;
          if (res.code === 1) {
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading2 = false;
        });
    },
    // 获取图表数据
    getCoordinatesLog() {
      this.loading = true;
      coordinatesLogReq({
        wechat_message_scenario_id: this.row.wechat_message_scenario_id,
        coordinates_type: this.form.coordinates_type,
        time_type: this.form.time_type,
        start_time: this.form.time[0],
        end_time: this.form.time[1],
      })
        .then((res) => {
          this.loading = false;
          if (res.code === 1) {
            this.chartData = res.data;
            const data = res.data.list;
            if (this.areaChart) {
              this.areaChart.destroy();
            }
            this.areaChart = new Area('container', {
              data,
              xField: 'time',
              yField: 'count',
              meta: {
                count: {
                  alias: '数量',
                },
              },
              areaStyle: () => {
                return {
                  fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
                };
              },
            });
            this.areaChart.render();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleSelect(val) {
      this.getTime();
      this.getCoordinatesLog();
    },
    // 获取默认时间
    getTime() {
      if (this.form.time_type === 'day') {
        this.form = {
          ...this.form,
          time: [ moment().subtract(15, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        };
      }
      if (this.form.time_type === 'month') {
        this.form = {
          ...this.form,
          time: [ moment().subtract(12, 'months').format('YYYY-MM'), moment().format('YYYY-MM')],
        };
      }
      if (this.form.time_type === 'year') {
        this.form = {
          ...this.form,
          time: [ moment().subtract(1, 'years').format('YYYY'), moment().format('YYYY')],
        };
      };
    },
    handleClick(val) {
      this.form = {
        ...this.form,
        coordinates_type: val,
      };
      this.getCoordinatesLog();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getDetailLog();
    },
    handleSizeChange(limit) {
      this.limit = limit;

    },
    goBack() {
      this.$emit('onBack');
    },
  },
}
</script>

<style lang="less" scoped>
.model {
  margin: 40px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.active {
  color: #409eff !important;
  background: #ecf5ff !important;
  border-color: #b3d8ff !important;
}
.tip {
  font-size: 14px;
  color: #909399;
  font-weight: normal;
  margin-left: 30px;
}
.box {
  display: flex;
  background: rgba(229,241,255,1);
  width: 164px;
  height: 32px;
  font-size: 14px;
  align-items: center;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 4px;
  color: #C4CFDF;
  margin-bottom: 10px;
  .btn-active {
    background: white !important;
    color: #565655 !important;
  }
  .btn1 {
    width: 80px;
    height: 24px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    transition: all .3s;
  }
  .btn2 {
    width: 80px;
    height: 24px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    transition: all .3s;
  }
}
.main {
  margin-top: 10px;
  background: white;
  .chart {
    padding-bottom: 30px;
    background: white;
    #container {
      width: 90%;
      margin: 0 auto;
    }
  }
  .form {
    height: 64px;
    padding: 12px 32px;
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(243,249,255,1) 100%);
    border-top: 1px solid #ddd;
    box-sizing: border-box;
    /deep/ .el-form-item {
      margin-bottom: 0px;
    }
  }
  .head {
    height: 48px;
    line-height: 48px;
    color: #333333;
    font-weight: bold;
    text-indent: 44px;
    font-size: 16px;
    position: relative;
    &::before {
      content: '';
      width: 4px;
      height: 16px;
      background: #1991FF;
      border-radius: 2px;
      position: absolute;
      top: 16px;
      left: 31px;
    }
  }
}
.title {
  margin-top: 10px;
  background: white;
  height: 48px;
  line-height: 48px;
  text-indent: 32px;
  font-weight: 700;
  font-size: 16px;
  color: #565656;
}
.main {
  margin-top: 10px;
}
/deep/ .el-page-header__title {
  color: #565656;
}
/deep/ .el-page-header__content {
  font-size: 16px;
  color: #565656;
}
.top {
  background: white;
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 32px;
}
</style>
