const __messageType__ = {
  1: '自定义消息',
  2: '会员引导消费消息',
  3: '生日关怀消息',
  4: '纪念日关怀消息',
  5: '月度积分消息',
  6: '老会员唤醒消息',
  7: '售后回访消息',
  8: '积分变更消息',
  9: '消费提醒消息',
  10: '关注成功消息',
  11: '会员等级变更消息',
  12: '注册成功消息',
  13: '积分兑换成功消息',
};

export default __messageType__;
