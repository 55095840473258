<template>
  <div>
    <div class="box" v-if="!templateVisible && !messageVisible">
      <div class="header">公众号管理</div>
      <div class="tip">温馨提示：请选择公众号进入消息管理，如果没有创建并绑定会员体系，需完成会员体系的创建和绑定</div>
      <el-button type="primary" class="btn" @click="toUrl" :disabled="!url? true: false">绑定授权</el-button>
      <el-divider></el-divider>
      <div class="list" v-if="weChartList.length">
        <div class="list-item" v-for="item in weChartList" :key="item.wechat_config_id">
          <div class="top">
            <div class="list-item-img">
              <img :src="item.head_img" style="width: 100%; height: 100%;" />
            </div>
            <div class="title">{{item.nick_name}}</div>
          </div>
          <div class="list-tip">
            温馨提示：需要同步公众号模板，才可进入模板消息 进行创建/编辑
          </div>
          <div class="list-btns">
            <el-button type="primary" class="btn1" @click="handleClick(item)">公众号模板</el-button>
            <el-button type="primary" plain class="btn2" :disabled="item.template_status ? false : true" @click="toMessage(item)">模板消息</el-button>
          </div>
        </div>
      </div>
      <div class="tip" style="text-align: center; margin-top: 100px;" v-else>暂无授权过的公众号</div>
    </div>
    <Template v-if="templateVisible" @onBack="handleBack" :item="currItem" />
    <Message v-if="messageVisible" @onBack="handleBack" :item="currItem" />
  </div>
</template>

<script>
import Template from './template.vue';
import Message from './message/List.vue';
import { getWeChatMessageListReq, getAuthRedirectReq } from '@/api/marketing/message';

export default {
  data() {
    return {
      currItem: {},
      url: '',
      weChartList: [],
      templateVisible: false,
      messageVisible: false,
    };
  },
  created() {
    this.getAuthRedirect();
    this.getWeChatMessageList();
  },
  methods: {
    // 去模板
    toMessage(item) {
      this.currItem = item;
      this.messageVisible = true;
    },
    // 公众号模板
    handleClick(item) {
      this.currItem = item;
      this.templateVisible = true;
    },
    // 去授权
    toUrl() {
      window.open(this.url);
    },
    // 获取授权地址
    getAuthRedirect() {
      getAuthRedirectReq()
        .then((res) => {
          if (res.code === 1) {
            this.url = res.data.a_url;
          }
        })
    },
    // 获取已授权的公众号
    getWeChatMessageList() {
      getWeChatMessageListReq()
        .then((res) => {
          if (res.code === 1) {
            this.weChartList = res.data;
          }
        });
    },
    handleBack() {
      this.templateVisible = false;
      this.messageVisible = false;
    },
  },
  components: {
    Template,
    Message,
  },
}
</script>

<style lang="less" scoped>
.box {
  background: white;
  padding: 32px;
  min-height: calc(100vh - 85px);
  box-sizing: border-box;
  .list {
    display: flex;
    &-btns {
      margin-top: 20px;
      .btn1, .btn2 {
        width: 180px;
        height: 40px;
      }
    }
    &-item {
      margin-right: 20px;
      width: 420px;
      height: 216px;
      border-radius: 6px;
      border: 1px solid rgba(240,241,245,1);
      box-shadow: 0 2px 10px 0 rgba(0,0,0,0.04);
      padding: 20px;
      box-sizing: border-box;
      .list-tip {
        width: 322px;
        color: #909399;
        margin-top: 20px;
      }
      .top {
        display: flex;
        align-items: center;
      }
      &-img {
        width: 48px;
        height: 48px;
        background: #C4CFDF;
        border-radius: 50%;
        overflow: hidden;
      }
      .title {
        font-size: 16px;
        font-weight: bold;
        margin-left: 10px;
        color: #565656;
      }
    }
  }
  .header {
    font-size: 16px;
    font-weight: bold;
    text-indent: 10px;
    position: relative;
    color: #333333;
    &::before {
      content: '';
      position: absolute;
      width: 4px;
      height: 16px;
      border-radius: 4px;
      background: #1991FF;
      left: 0;
      top: 4px;
    }
  }
  .tip {
    font-size: 14px;
    color: #909399;
    margin-top: 10px;
  }
  .btn {
    width: 140px;
    height: 40px;
    margin-top: 20px;
  }
  /deep/ .el-divider {
    background: rgba(240,241,245,1);
  }
}
</style>
