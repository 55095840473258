<template>
  <div>
    <div class="top">
      <el-page-header @back="goBack" :content="__messageType__[row.type]">
      </el-page-header>
    </div>
    <div class="main">
      <div class="title">应用公众号：{{ item.nick_name }}</div>
      <div class="box">
        <div class="left">
          <div
            class="phone"
            :style="{ backgroundImage: 'url(' + iphoneImg + ')' }"
          >
            <div class="item">
              <div class="model" v-if="row.is_marketing === 1">
                <div class="item-title">消息标题</div>
                <!-- <div class="item-content">{{form.title}}</div> -->
              </div>
              <div class="model">
                <!-- <div class="item-title" v-if="!form.list[currentIndex].data.first.value">
                  开始语
                </div> -->
                <div
                  class="item-content"
                  :style="{ color: form.list[currentIndex].data.first.color }"
                >
                  {{ form.list[currentIndex].data.first.value }}
                </div>
              </div>
              <div class="model" v-if="row.is_marketing === 1">
                <div class="flex">
                  <div class="model-left">服务类型</div>
                  <div
                    class="model-right"
                    :style="{
                      color: form.list[currentIndex].data.keyword1.color,
                    }"
                  >
                    {{ form.list[currentIndex].data.keyword1.value }}
                  </div>
                </div>
                <div class="flex">
                  <div class="model-left">服务状态</div>
                  <div
                    class="model-right"
                    :style="{
                      color: form.list[currentIndex].data.keyword2.color,
                    }"
                  >
                    {{ form.list[currentIndex].data.keyword2.value }}
                  </div>
                </div>
                <div class="flex">
                  <div class="model-left">服务时间</div>
                  <div
                    class="model-right"
                    :style="{
                      color: form.list[currentIndex].data.keyword3.color,
                    }"
                  >
                    {{ form.list[currentIndex].data.keyword3.value }}
                  </div>
                </div>
              </div>
              <div class="model" v-if="row.is_marketing === 2">
                <template v-if="row.type === 8">
                  <div class="flex">
                    <div class="model-left">原有数量</div>
                    <div
                      class="model-right"
                      :style="{
                        color: form.list[currentIndex].data.keyword1.color,
                      }"
                    >
                      {{ form.list[currentIndex].data.keyword1.value }}
                    </div>
                  </div>
                  <div class="flex">
                    <div class="model-left">变更结果</div>
                    <div
                      class="model-right"
                      :style="{
                        color: form.list[currentIndex].data.keyword2.color,
                      }"
                    >
                      {{ form.list[currentIndex].data.keyword2.value }}
                    </div>
                  </div>
                  <div class="flex">
                    <div class="model-left">变更时间</div>
                    <div
                      class="model-right"
                      :style="{
                        color: form.list[currentIndex].data.keyword3.color,
                      }"
                    >
                      {{ form.list[currentIndex].data.keyword3.value }}
                    </div>
                  </div>
                </template>
                <template v-if="row.type === 9">
                  <div class="flex">
                    <div class="model-left">订单编号</div>
                    <div
                      class="model-right"
                      :style="{
                        color: form.list[currentIndex].data.keyword1.color,
                      }"
                    >
                      {{ form.list[currentIndex].data.keyword1.value }}
                    </div>
                  </div>
                  <div class="flex">
                    <div class="model-left">商品名称</div>
                    <div
                      class="model-right"
                      :style="{
                        color: form.list[currentIndex].data.keyword2.color,
                      }"
                    >
                      {{ form.list[currentIndex].data.keyword2.value }}
                    </div>
                  </div>
                  <div class="flex">
                    <div class="model-left">支付金额</div>
                    <div
                      class="model-right"
                      :style="{
                        color: form.list[currentIndex].data.keyword3.color,
                      }"
                    >
                      {{ form.list[currentIndex].data.keyword3.value }}
                    </div>
                  </div>
                  <div class="flex">
                    <div class="model-left">支付时间</div>
                    <div
                      class="model-right"
                      :style="{
                        color: form.list[currentIndex].data.keyword4.color,
                      }"
                    >
                      {{ form.list[currentIndex].data.keyword4.value }}
                    </div>
                  </div>
                  <div class="flex">
                    <div class="model-left">送达时间</div>
                    <div
                      class="model-right"
                      :style="{
                        color: form.list[currentIndex].data.keyword5.color,
                      }"
                    >
                      {{ form.list[currentIndex].data.keyword5.value }}
                    </div>
                  </div>
                </template>
                <template v-if="row.type === 10">
                  <div class="flex">
                    <div class="model-left">操作流程</div>
                    <div
                      class="model-right"
                      :style="{
                        color: form.list[currentIndex].data.keyword1.color,
                      }"
                    >
                      {{ form.list[currentIndex].data.keyword1.value }}
                    </div>
                  </div>
                  <div class="flex">
                    <div class="model-left">客服电话</div>
                    <div
                      class="model-right"
                      :style="{
                        color: form.list[currentIndex].data.keyword2.color,
                      }"
                    >
                      {{ form.list[currentIndex].data.keyword2.value }}
                    </div>
                  </div>
                </template>
                <template v-if="row.type === 11">
                  <div class="flex">
                    <div class="model-left">会员卡号</div>
                    <div
                      class="model-right"
                      :style="{
                        color: form.list[currentIndex].data.keyword1.color,
                      }"
                    >
                      {{ form.list[currentIndex].data.keyword1.value }}
                    </div>
                  </div>
                  <div class="flex">
                    <div class="model-left">会员等级</div>
                    <div
                      class="model-right"
                      :style="{
                        color: form.list[currentIndex].data.keyword2.color,
                      }"
                    >
                      {{ form.list[currentIndex].data.keyword2.value }}
                    </div>
                  </div>
                </template>
                <template v-if="row.type === 12">
                  <div class="flex">
                    <div class="model-left">注册用户</div>
                    <div
                      class="model-right"
                      :style="{
                        color: form.list[currentIndex].data.keyword1.color,
                      }"
                    >
                      {{ form.list[currentIndex].data.keyword1.value }}
                    </div>
                  </div>
                  <div class="flex">
                    <div class="model-left">注册时间</div>
                    <div
                      class="model-right"
                      :style="{
                        color: form.list[currentIndex].data.keyword2.color,
                      }"
                    >
                      {{ form.list[currentIndex].data.keyword2.value }}
                    </div>
                  </div>
                  <div class="flex">
                    <div class="model-left">注册来源</div>
                    <div
                      class="model-right"
                      :style="{
                        color: form.list[currentIndex].data.keyword3.color,
                      }"
                    >
                      {{ form.list[currentIndex].data.keyword3.value }}
                    </div>
                  </div>
                </template>
                <template v-if="row.type === 13">
                  <div class="flex">
                    <div class="model-left">兑换码</div>
                    <div
                      class="model-right"
                      :style="{
                        color: form.list[currentIndex].data.keyword1.color,
                      }"
                    >
                      {{ form.list[currentIndex].data.keyword1.value }}
                    </div>
                  </div>
                  <div class="flex">
                    <div class="model-left">商户名称</div>
                    <div
                      class="model-right"
                      :style="{
                        color: form.list[currentIndex].data.keyword2.color,
                      }"
                    >
                      {{ form.list[currentIndex].data.keyword2.value }}
                    </div>
                  </div>
                  <div class="flex">
                    <div class="model-left">兑换内容</div>
                    <div
                      class="model-right"
                      :style="{
                        color: form.list[currentIndex].data.keyword3.color,
                      }"
                    >
                      {{ form.list[currentIndex].data.keyword3.value }}
                    </div>
                  </div>
                  <div class="flex">
                    <div class="model-left">有效期</div>
                    <div
                      class="model-right"
                      :style="{
                        color: form.list[currentIndex].data.keyword4.color,
                      }"
                    >
                      {{ form.list[currentIndex].data.keyword4.value }}
                    </div>
                  </div>
                  <div class="flex">
                    <div class="model-left">剩余积分</div>
                    <div
                      class="model-right"
                      :style="{
                        color: form.list[currentIndex].data.keyword5.color,
                      }"
                    >
                      {{ form.list[currentIndex].data.keyword5.value }}
                    </div>
                  </div>
                </template>
              </div>
              <div>
                <!-- <div class="item-title">
                  结束语
                </div> -->
                <div
                  class="item-content"
                  :style="{ color: form.list[currentIndex].data.remark.color }"
                >
                  {{ form.list[currentIndex].data.remark.value }}
                </div>
              </div>
              <template v-if="form.list[currentIndex].url_type">
                <el-divider></el-divider>
                <div class="link">
                  <span style="color: #565656">详情</span>
                  <i class="el-icon-arrow-right"></i>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="right">
          <el-form
            :model="form"
            label-width="100px"
            :rules="rules"
            ref="ruleForm"
          >
            <el-form-item
              label="消息名称"
              required=""
              prop="title"
              v-if="row.is_marketing === 1"
            >
              <el-input
                v-model="form.title"
                placeholder="请输入消息名称"
                style="width: 280px"
                maxlength="15"
                show-word-limit
              />
              <span style="color: #909399; font-size: 14px; margin-left: 10px"
                >提示：消息名称1-15个字以内</span
              >
            </el-form-item>
            <el-form-item label="发送对象" prop="send_object">
              <el-radio v-model="form.send_object" :label="1"
                >所有会员</el-radio
              >
            </el-form-item>
            <el-form-item
              label="推送任务"
              required=""
              v-if="row.type === 2 || row.type === 7"
              style="margin-bottom: 0px"
            >
              <div style="color: #909399; font-size: 14px">
                提示：至少添加一个推送任务,最多添加5个
              </div>
              <div class="tabs">
                <div
                  class="tab"
                  v-for="(item, index) in tabs"
                  :key="item.id"
                  :class="currentIndex === index ? 'tab-active' : ''"
                  @click="handleClick(index)"
                >
                  <div style="display: flex">
                    <div style="flex-shrink: 0">
                      <span v-if="row.type === 2">注册</span>
                      <span v-if="row.type === 7">售后</span>
                    </div>
                    <el-input
                      placeholder="请输入"
                      type="number"
                      class="no-number"
                      v-model="form.list[index].timeValue"
                      style="width: 80px; margin: 0 10px; flex-shrink: 0"
                      @input="handleInput(index)"
                    />
                    <el-select
                      v-model="form.list[index].timeType"
                      style="width: 95px; margin-right: 10px; flex-shrink: 0"
                      @change="handleChange($event, index)"
                    >
                      <template v-for="item in timeTypeList">
                        <el-option
                          v-if="(row.type === 7 && item.value === 'minute') ? false : true"
                          :label="item.txt"
                          :value="item.value"
                          :key="item.value"
                        ></el-option>
                      </template>
                    </el-select>
                    <div>后</div>
                  </div>
                  <div style="text-align: center; margin-top: 5px">
                    <el-time-picker
                      value-format="HH:mm:ss"
                      v-if="
                        form.list[index].timeType === 'day' ||
                        form.list[index].timeType === 'month'
                      "
                      style="width: 130px"
                      v-model="form.list[index].time"
                      placeholder="--:--:--"
                    >
                    </el-time-picker>
                    推送消息
                  </div>
                  <div
                    class="el-icon-error"
                    @click.stop="handlDele(index)"
                    v-if="index !== 0"
                  ></div>
                </div>
                <div class="plus" @click="handleAdd" v-if="tabs.length !== 5">
                  <div class="el-icon-plus"></div>
                  <div style="margin-top: 5px">新增</div>
                </div>
              </div>
            </el-form-item>
            <el-form-item
              label="推送时间"
              required=""
              v-if="row.type === 3"
              :prop="'list.' + currentIndex + '.day'"
            >
              <div style="color: #565656">
                会员生日前<el-input
                  placeholder="请输入"
                  style="width: 74px; margin: 0 10px"
                  type="number"
                  class="no-number"
                  v-model="form.list[currentIndex].day"
                />天
                <el-time-picker
                  v-model="form.list[currentIndex].time"
                  style="width: 120px; margin: 0 10px"
                  value-format="HH:mm:ss"
                  placeholder="-- -- --"
                >
                </el-time-picker>
                <span style="color: #909399"
                  >推送（0代表当天，最大支持提前60天）</span
                >
              </div>
            </el-form-item>
            <el-form-item
              label="推送时间"
              required=""
              v-if="row.type === 4"
              :prop="'list.' + currentIndex + '.day'"
            >
              <div style="color: #565656">
                会员纪念日前<el-input
                  placeholder="请输入"
                  style="width: 74px; margin: 0 10px"
                  type="number"
                  class="no-number"
                  v-model="form.list[currentIndex].day"
                />天
                <el-time-picker
                  style="width: 120px; margin: 0 10px"
                  v-model="form.list[currentIndex].time"
                  value-format="HH:mm:ss"
                  placeholder="-- -- --"
                >
                </el-time-picker>
                <span style="color: #909399"
                  >推送（0代表当天，最大支持提前60天）</span
                >
              </div>
            </el-form-item>
            <el-form-item
              label="推送时间"
              required=""
              v-if="row.type === 5"
              :prop="'list.' + currentIndex + '.month'"
            >
              <div style="color: #565656">
                固定每月的<el-input
                  placeholder="请输入"
                  style="width: 74px; margin: 0 10px"
                  type="number"
                  class="no-number"
                  v-model="form.list[currentIndex].month"
                />号
                <el-time-picker
                  style="width: 120px; margin: 0 10px"
                  v-model="form.list[currentIndex].time"
                  value-format="HH:mm:ss"
                  placeholder="-- -- --"
                >
                </el-time-picker>
                <span style="color: #909399">推送</span>
              </div>
            </el-form-item>
            <el-form-item
              label="推送时间"
              required=""
              v-if="row.type === 6"
              :prop="'list.' + currentIndex + '.day'"
            >
              <div style="color: #565656">
                最后消费<el-input
                  placeholder="请输入"
                  style="width: 74px; margin: 0 10px"
                  type="number"
                  class="no-number"
                  v-model="form.list[currentIndex].day"
                />天后的
                <el-time-picker
                  style="width: 120px; margin: 0 10px"
                  v-model="form.list[currentIndex].time"
                  value-format="HH:mm:ss"
                  placeholder="-- -- --"
                >
                </el-time-picker>
                <span style="color: #909399"
                  >推送（0天代表当天，最大支持提前360天）</span
                >
              </div>
            </el-form-item>
            <el-form-item
              label="发送周期"
              prop="start_cycle"
              required=""
              v-if="row.type === 1"
            >
              <span style="margin-right: 10px; color: #565656">开始日期</span>
              <el-date-picker
                v-model="form.start_cycle"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
                :picker-options="pickerOptions0"
              >
              </el-date-picker>
              <span style="margin: 0px 10px; color: #565656">结束日期</span>
              <el-date-picker
                v-model="form.end_cycle"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="pickerOptions0"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              <span style="color: #909399; font-size: 14px; margin-left: 10px"
                >提示：可以不填结束日期，如果不填则永久有效</span
              >
            </el-form-item>
            <el-form-item
              label="发送方式"
              required=""
              prop="list.0.method"
              v-if="row.type === 1"
            >
              <el-radio-group
                v-model="form.list[0].method"
                @change="handleRadio"
              >
                <el-radio :label="0" style="display: flex; align-items: center">
                  <div style="height: 40px; line-height: 40px">仅一次</div>
                </el-radio>
                <el-radio :label="1" style="display: flex; align-items: center">
                  <div class="radio-box">
                    间隔<el-input
                      placeholder="请输入"
                      style="
                        width: 80px;
                        margin: 0 10px;
                        text-align: center;
                        height: 36px;
                      "
                      v-model="form.list[0].day"
                      type="number"
                      class="no-number"
                    />天发送
                  </div>
                </el-radio>
                <el-radio :label="2" style="display: flex; align-items: center">
                  <div class="radio-box">
                    每周<el-select
                      v-model="form.list[0].week"
                      placeholder="请选择"
                      style="width: 130px; margin: 0 10px"
                    >
                      <template v-for="item in weekList">
                        <el-option
                          :key="item.value"
                          :label="item.txt"
                          :value="item.value"
                        ></el-option>
                      </template> </el-select
                    >发送
                  </div>
                </el-radio>
                <el-radio :label="3" style="display: flex; align-items: center">
                  <div class="radio-box">
                    每月<el-select
                      v-model="form.list[0].month"
                      placeholder="请选择"
                      style="width: 130px; margin: 0 10px"
                    >
                      <template v-for="item in dayList">
                        <el-option
                          :key="item.value"
                          :label="item.txt"
                          :value="item.value"
                        ></el-option>
                      </template> </el-select
                    >发送
                  </div>
                </el-radio>
                <el-radio :label="4" style="display: flex; align-items: center">
                  <div class="radio-box">
                    每月<el-select
                      v-model="form.list[0].many_weeks"
                      placeholder="请选择"
                      style="width: 130px; margin: 0 10px"
                    >
                      <template v-for="item in many_weeks">
                        <el-option
                          :key="item.value"
                          :label="item.txt"
                          :value="item.value"
                        ></el-option>
                      </template>
                    </el-select>
                    <el-select
                      v-model="form.list[0].month_week"
                      placeholder="请选择"
                      style="width: 130px; margin-right: 10px"
                    >
                      <template v-for="item in weekList">
                        <el-option
                          :key="item.value"
                          :label="item.txt"
                          :value="item.value"
                        ></el-option>
                      </template> </el-select
                    >发送
                  </div>
                </el-radio>
              </el-radio-group>
            </el-form-item>

            <div
              :class="row.type === 2 || row.type === 7 ? 'border' : ''"
              v-for="(item, index) in form.list"
              :key="index"
              v-show="currentIndex === index"
            >
              <el-form-item
                label="开始语"
                required=""
                :prop="'list.' + index + '.data.first.value'"
              >
                <div style="display: flex">
                  <el-input
                    id="input1"
                    type="textarea"
                    rows="6"
                    style="width: 600px;"
                    clearable=""
                    maxlength="100"
                    show-word-limit
                    v-model="form.list[index].data.first.value"
                    placeholder="请输入开始语"
                  />
                  <div>
                    <!-- <div
                      style="
                        display: flex;
                        align-items: center;
                        border: 1px solid #ddd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                        margin-bottom: 10px;
                      "
                    >
                      <el-input
                        type="color"
                        style="width: 70px; margin-right: 5px; cursor: pointer"
                        v-model="form.list[index].data.first.color"
                        class="color"
                      />
                      {{ form.list[index].data.first.color }}
                    </div> -->
                    <el-select
                      placeholder="插入参数"
                      v-model="form.list[index].data.first.params"
                      style="margin-left: 10px; width: 130px"
                      @change="handleChangeSelect(index, 'first', 'input1')"
                    >
                      <template v-for="item in parameterList">
                        <el-option
                          :key="item.parameter_id"
                          :label="item.value"
                          :value="item.key"
                        ></el-option>
                      </template>
                    </el-select>
                    <div
                      style="color: #909399; font-size: 14px; margin-left: 10px"
                    >
                      提示：可往消息内容插入参数
                    </div>
                  </div>
                </div>
              </el-form-item>
              <el-form-item
                label="服务类型"
                required=""
                :prop="'list.' + index + '.data.keyword1.value'"
                v-if="row.is_marketing === 1"
              >
                <div style="display: flex">
                  <el-input
                    :style="{
                      width: '600px',
                      color: form.list[index].data.keyword1.color,
                    }"
                    maxlength="30"
                    show-word-limit
                    v-model="form.list[index].data.keyword1.value"
                    placeholder="请输入服务类型"
                  />
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      border: 1px solid #ddd;
                      box-sizing: border-box;
                      border-radius: 4px;
                      margin-left: 10px;
                      padding: 0 10px;
                      width: 110px;
                    "
                  >
                    <el-input
                      type="color"
                      style="width: 70px; margin-right: 5px; cursor: pointer"
                      v-model="form.list[index].data.keyword1.color"
                      class="color"
                    />
                    {{ form.list[index].data.keyword1.color }}
                  </div>
                </div>
              </el-form-item>
              <el-form-item
                label="服务状态"
                required=""
                :prop="'list.' + index + '.data.keyword2.value'"
                v-if="row.is_marketing === 1"
              >
                <div style="display: flex">
                  <el-input
                    :style="{
                      width: '600px',
                      color: form.list[index].data.keyword2.color,
                    }"
                    maxlength="30"
                    show-word-limit
                    v-model="form.list[index].data.keyword2.value"
                    placeholder="请输入服务状态"
                  />
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      border: 1px solid #ddd;
                      box-sizing: border-box;
                      border-radius: 4px;
                      margin-left: 10px;
                      padding: 0 10px;
                      width: 110px;
                    "
                  >
                    <el-input
                      type="color"
                      style="width: 70px; margin-right: 5px; cursor: pointer"
                      v-model="form.list[index].data.keyword2.color"
                      class="color"
                    />
                    {{ form.list[index].data.keyword2.color }}
                  </div>
                </div>
              </el-form-item>
              <el-form-item
                label="服务时间"
                :prop="'list.' + index + '.data.keyword3.value'"
                v-if="
                  row.type !== 10 &&
                  row.type !== 11 &&
                  row.type !== 12 &&
                  row.type !== 13 &&
                  row.is_marketing === 1
                "
              >
                <div style="display: flex">
                  <el-input
                    :style="{
                      width: '600px',
                      color: form.list[index].data.keyword3.color,
                    }"
                    v-model="form.list[index].data.keyword3.value"
                    placeholder="#推送时间#"
                    :disabled="true"
                  />
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      border: 1px solid #ddd;
                      box-sizing: border-box;
                      border-radius: 4px;
                      margin-left: 10px;
                      padding: 0 10px;
                      width: 110px;
                    "
                  >
                    <el-input
                      type="color"
                      style="width: 70px; margin-right: 5px; cursor: pointer"
                      v-model="form.list[index].data.keyword3.color"
                      class="color"
                    />
                    {{ form.list[index].data.keyword3.color }}
                  </div>
                </div>
              </el-form-item>
              <template v-if="row.type === 8">
                <el-form-item label="原有数量">
                  <div style="display: flex">
                    <el-input
                      style="width: 600px"
                      v-model="form.list[index].data.keyword1.value"
                      placeholder="请输入"
                      :disabled="true"
                    />
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        border: 1px solid #ddd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                      "
                    >
                      <el-input
                        type="color"
                        style="width: 70px; margin-right: 5px; cursor: pointer"
                        v-model="form.list[index].data.keyword1.color"
                        class="color"
                      />
                      {{ form.list[index].data.keyword1.color }}
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="变更结果">
                  <div style="display: flex">
                    <el-input
                      style="width: 600px"
                      v-model="form.list[index].data.keyword2.value"
                      placeholder="请输入"
                      :disabled="true"
                    />
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        border: 1px solid #ddd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                      "
                    >
                      <el-input
                        type="color"
                        style="width: 70px; margin-right: 5px; cursor: pointer"
                        v-model="form.list[index].data.keyword2.color"
                        class="color"
                      />
                      {{ form.list[index].data.keyword2.color }}
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="变更时间">
                  <div style="display: flex">
                    <el-input
                      style="width: 600px"
                      v-model="form.list[index].data.keyword3.value"
                      placeholder="请输入"
                      :disabled="true"
                    />
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        border: 1px solid #ddd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                      "
                    >
                      <el-input
                        type="color"
                        style="width: 70px; margin-right: 5px; cursor: pointer"
                        v-model="form.list[index].data.keyword3.color"
                        class="color"
                      />
                      {{ form.list[index].data.keyword3.color }}
                    </div>
                  </div>
                </el-form-item>
              </template>
              <template v-if="row.type === 9">
                <el-form-item label="订单编号">
                  <div style="display: flex">
                    <el-input
                      style="width: 600px"
                      v-model="form.list[index].data.keyword1.value"
                      placeholder="请输入"
                      :disabled="true"
                    />
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        border: 1px solid #ddd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                      "
                    >
                      <el-input
                        type="color"
                        style="width: 70px; margin-right: 5px; cursor: pointer"
                        v-model="form.list[index].data.keyword1.color"
                        class="color"
                      />
                      {{ form.list[index].data.keyword1.color }}
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="商品名称">
                  <div style="display: flex">
                    <el-input
                      style="width: 600px"
                      v-model="form.list[index].data.keyword2.value"
                      placeholder="请输入"
                      :disabled="true"
                    />
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        border: 1px solid #ddd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                      "
                    >
                      <el-input
                        type="color"
                        style="width: 70px; margin-right: 5px; cursor: pointer"
                        v-model="form.list[index].data.keyword2.color"
                        class="color"
                      />
                      {{ form.list[index].data.keyword2.color }}
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="支付金额">
                  <div style="display: flex">
                    <el-input
                      style="width: 600px"
                      v-model="form.list[index].data.keyword3.value"
                      placeholder="请输入"
                      :disabled="true"
                    />
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        border: 1px solid #ddd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                      "
                    >
                      <el-input
                        type="color"
                        style="width: 70px; margin-right: 5px; cursor: pointer"
                        v-model="form.list[index].data.keyword3.color"
                        class="color"
                      />
                      {{ form.list[index].data.keyword3.color }}
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="支付时间">
                  <div style="display: flex">
                    <el-input
                      style="width: 600px"
                      v-model="form.list[index].data.keyword4.value"
                      placeholder="请输入"
                      :disabled="true"
                    />
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        border: 1px solid #ddd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                      "
                    >
                      <el-input
                        type="color"
                        style="width: 70px; margin-right: 5px; cursor: pointer"
                        v-model="form.list[index].data.keyword4.color"
                        class="color"
                      />
                      {{ form.list[index].data.keyword4.color }}
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="送达时间">
                  <div style="display: flex">
                    <el-input
                      style="width: 600px"
                      v-model="form.list[index].data.keyword5.value"
                      placeholder="请输入"
                      :disabled="true"
                    />
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        border: 1px solid #ddd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                      "
                    >
                      <el-input
                        type="color"
                        style="width: 70px; margin-right: 5px; cursor: pointer"
                        v-model="form.list[index].data.keyword5.color"
                        class="color"
                      />
                      {{ form.list[index].data.keyword5.color }}
                    </div>
                  </div>
                </el-form-item>
              </template>
              <template v-if="row.type === 10">
                <el-form-item label="操作流程">
                  <div style="display: flex">
                    <el-input
                      style="width: 600px"
                      v-model="form.list[index].data.keyword1.value"
                      placeholder="请输入"
                      :disabled="true"
                    />
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        border: 1px solid #ddd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                      "
                    >
                      <el-input
                        type="color"
                        style="width: 70px; margin-right: 5px; cursor: pointer"
                        v-model="form.list[index].data.keyword1.color"
                        class="color"
                      />
                      {{ form.list[index].data.keyword1.color }}
                    </div>
                  </div>
                </el-form-item>
                
                <el-form-item
                  label="客服电话"
                  required=""
                  :prop="'list.' + index + '.data.keyword2.value'"
                >
                  <div style="display: flex">
                    <el-input
                      style="width: 600px"
                      v-model="form.list[index].data.keyword2.value"
                      placeholder="请输入"
                    />
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        border: 1px solid #ddd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                      "
                    >
                      <el-input
                        type="color"
                        style="width: 70px; margin-right: 5px; cursor: pointer"
                        v-model="form.list[index].data.keyword2.color"
                        class="color"
                      />
                      {{ form.list[index].data.keyword2.color }}
                    </div>
                  </div>
                </el-form-item>
              </template>
              <template v-if="row.type === 11">
                <el-form-item label="会员卡号">
                  <div style="display: flex">
                    <el-input
                      style="width: 600px"
                      v-model="form.list[index].data.keyword1.value"
                      placeholder="请输入"
                      :disabled="true"
                    />
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        border: 1px solid #ddd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                      "
                    >
                      <el-input
                        type="color"
                        style="width: 70px; margin-right: 5px; cursor: pointer"
                        v-model="form.list[index].data.keyword1.color"
                        class="color"
                      />
                      {{ form.list[index].data.keyword1.color }}
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="会员等级">
                  <div style="display: flex">
                    <el-input
                      style="width: 600px"
                      v-model="form.list[index].data.keyword2.value"
                      placeholder="请输入"
                      :disabled="true"
                    />
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        border: 1px solid #ddd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                      "
                    >
                      <el-input
                        type="color"
                        style="width: 70px; margin-right: 5px; cursor: pointer"
                        v-model="form.list[index].data.keyword2.color"
                        class="color"
                      />
                      {{ form.list[index].data.keyword2.color }}
                    </div>
                  </div>
                </el-form-item>
              </template>
              <template v-if="row.type === 12">
                <el-form-item label="注册用户">
                  <div style="display: flex">
                    <el-input
                      style="width: 600px"
                      v-model="form.list[index].data.keyword1.value"
                      placeholder="请输入"
                      :disabled="true"
                    />
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        border: 1px solid #ddd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                      "
                    >
                      <el-input
                        type="color"
                        style="width: 70px; margin-right: 5px; cursor: pointer"
                        v-model="form.list[index].data.keyword1.color"
                        class="color"
                      />
                      {{ form.list[index].data.keyword1.color }}
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="注册时间">
                  <div style="display: flex">
                    <el-input
                      style="width: 600px"
                      v-model="form.list[index].data.keyword2.value"
                      placeholder="请输入"
                      :disabled="true"
                    />
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        border: 1px solid #ddd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                      "
                    >
                      <el-input
                        type="color"
                        style="width: 70px; margin-right: 5px; cursor: pointer"
                        v-model="form.list[index].data.keyword2.color"
                        class="color"
                      />
                      {{ form.list[index].data.keyword2.color }}
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="注册来源">
                  <div style="display: flex">
                    <el-input
                      style="width: 600px"
                      v-model="form.list[index].data.keyword3.value"
                      placeholder="请输入"
                      :disabled="true"
                    />
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        border: 1px solid #ddd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                      "
                    >
                      <el-input
                        type="color"
                        style="width: 70px; margin-right: 5px; cursor: pointer"
                        v-model="form.list[index].data.keyword3.color"
                        class="color"
                      />
                      {{ form.list[index].data.keyword3.color }}
                    </div>
                  </div>
                </el-form-item>
              </template>
              <template v-if="row.type === 13">
                <el-form-item label="兑换码">
                  <div style="display: flex">
                    <el-input
                      style="width: 600px"
                      v-model="form.list[index].data.keyword1.value"
                      placeholder="请输入"
                      :disabled="true"
                    />
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        border: 1px solid #ddd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                      "
                    >
                      <el-input
                        type="color"
                        style="width: 70px; margin-right: 5px; cursor: pointer"
                        v-model="form.list[index].data.keyword1.color"
                        class="color"
                      />
                      {{ form.list[index].data.keyword1.color }}
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="商户名称">
                  <div style="display: flex">
                    <el-input
                      style="width: 600px"
                      v-model="form.list[index].data.keyword2.value"
                      placeholder="请输入"
                      :disabled="true"
                    />
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        border: 1px solid #ddd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                      "
                    >
                      <el-input
                        type="color"
                        style="width: 70px; margin-right: 5px; cursor: pointer"
                        v-model="form.list[index].data.keyword2.color"
                        class="color"
                      />
                      {{ form.list[index].data.keyword2.color }}
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="兑换内容">
                  <div style="display: flex">
                    <el-input
                      style="width: 600px"
                      v-model="form.list[index].data.keyword3.value"
                      placeholder="请输入"
                      :disabled="true"
                    />
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        border: 1px solid #ddd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                      "
                    >
                      <el-input
                        type="color"
                        style="width: 70px; margin-right: 5px; cursor: pointer"
                        v-model="form.list[index].data.keyword3.color"
                        class="color"
                      />
                      {{ form.list[index].data.keyword3.color }}
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="有效期">
                  <div style="display: flex">
                    <el-input
                      style="width: 600px"
                      v-model="form.list[index].data.keyword4.value"
                      placeholder="请输入"
                      :disabled="true"
                    />
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        border: 1px solid #ddd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                      "
                    >
                      <el-input
                        type="color"
                        style="width: 70px; margin-right: 5px; cursor: pointer"
                        v-model="form.list[index].data.keyword4.color"
                        class="color"
                      />
                      {{ form.list[index].data.keyword4.color }}
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="剩余积分">
                  <div style="display: flex">
                    <el-input
                      style="width: 600px"
                      v-model="form.list[index].data.keyword5.value"
                      placeholder="请输入"
                      :disabled="true"
                    />
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        border: 1px solid #ddd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                      "
                    >
                      <el-input
                        type="color"
                        style="width: 70px; margin-right: 5px; cursor: pointer"
                        v-model="form.list[index].data.keyword5.color"
                        class="color"
                      />
                      {{ form.list[index].data.keyword5.color }}
                    </div>
                  </div>
                </el-form-item>
              </template>
              <el-form-item
                label="结束语"
                required=""
                :prop="'list.' + index + '.data.remark.value'"
              >
                <div style="display: flex">
                  <el-input
                    id="input2"
                    type="textarea"
                    rows="6"
                    style="width: 600px"
                    maxlength="100"
                    show-word-limit
                    v-model="form.list[index].data.remark.value"
                    placeholder="请输入结束语"
                  />
                  <div>
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        border: 1px solid #ddd;
                        box-sizing: border-box;
                        border-radius: 4px;
                        margin-left: 10px;
                        padding: 0 10px;
                        width: 110px;
                        margin-bottom: 10px;
                      "
                    >
                      <el-input
                        type="color"
                        style="width: 70px; margin-right: 5px; cursor: pointer"
                        v-model="form.list[index].data.remark.color"
                        class="color"
                      />
                      {{ form.list[index].data.remark.color }}
                    </div>
                    <el-select
                      placeholder="插入参数"
                      v-model="form.list[index].data.remark.params"
                      style="margin-left: 10px; width: 130px"
                      @change="handleChangeSelect(index, 'remark', 'input2')"
                    >
                      <template v-for="item in parameterList">
                        <el-option
                          :key="item.parameter_id"
                          :label="item.value"
                          :value="item.key"
                        ></el-option>
                      </template>
                    </el-select>
                    <div
                      style="color: #909399; font-size: 14px; margin-left: 10px"
                    >
                      提示：可往消息内容插入参数
                    </div>
                  </div>
                </div>
              </el-form-item>

              <el-form-item
                label="详情链接"
                required=""
                :prop="'list.' + index + '.url_type'"
              >
                <el-radio-group
                  v-model="form.list[index].url_type"
                  @change="changeRadio(index)"
                >
                  <el-radio :label="1">自定义链接</el-radio>
                  <el-radio :label="0">无需跳转</el-radio>
                </el-radio-group>
                <div>
                  <el-input
                    v-if="form.list[index].url_type"
                    v-model="form.list[index].url"
                    placeholder="请输入自定义链接"
                    style="margin-left: 40px; width: 600px"
                    clearable=""
                  />
                </div>
              </el-form-item>
            </div>

            <el-form-item label="消息状态" required="" prop="status">
              <el-switch
                v-model="form.status"
                :active-value="1"
                :inactive-value="0"
                active-text="启用"
              >
              </el-switch>
            </el-form-item>
          </el-form>
          <div style="height: 60px"></div>
          <div class="btns">
            <el-button type="primary" plain style="width: 120px" @click="goBack"
              >返回</el-button
            >
            <el-button
              type="primary"
              style="width: 120px"
              @click="submitForm('ruleForm')"
              :loading="saveLoading"
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getParameterReq, saveReq, readReq } from "@/api/marketing/message";
import __messageType__ from "@/utils/messageType.js";

export default {
  data() {
    // 检查月号
    const checkMonthDay = (rule, value, callback) => {
      const list = this.form.list;
      if (this.row.type === 5 && !list[this.currentIndex].month) {
        return callback(new Error("请输入号"));
      }
      if (this.row.type === 5 && !list[this.currentIndex].time) {
        return callback(new Error("请选择时分秒"));
      }
      if (
        this.row.type === 5 &&
        (Number(list[this.currentIndex].month) > 31 ||
          Number(list[this.currentIndex].month) <= 0)
      ) {
        return callback(
          new Error(
            "设置范围1-31日，当设置值大于小月天数时，则在当月最后一天推送"
          )
        );
      }
      callback();
    };
    // 检查天（有限制）
    const checkDay = (rule, value, callback) => {
      const list = this.form.list;
      if (
        (this.row.type === 3 || this.row.type === 4 ) &&
        !list[this.currentIndex].day
      ) {
        return callback(new Error("请输入天数"));
      }
      if (this.row.type === 6 && !list[this.currentIndex].day && list[this.currentIndex].day !== 0) {
        return callback(new Error("请输入天数"));
      }
      if (
        (this.row.type === 3 || this.row.type === 4 || this.row.type === 6) &&
        !list[this.currentIndex].time
      ) {
        return callback(new Error("请选择时分秒"));
      }
      if (
        (this.row.type === 3 || this.row.type === 4) &&
        (Number(list[this.currentIndex].day) > 60 ||
          Number(list[this.currentIndex].day) < 0)
      ) {
        return callback(new Error("最大支持提前60天"));
      }
      if (
        this.row.type === 6 &&
        (Number(list[this.currentIndex].day) > 360 ||
          Number(list[this.currentIndex].day) < 0)
      ) {
        return callback(new Error("最大支持提前360天"));
      }
      callback();
    };
    // 检查url
    const checkUrl = (rule, value, callback) => {
      const list = this.form.list;
      if (list[this.currentIndex].url_type && !list[this.currentIndex].url) {
        return callback(new Error("请输入链接"));
      }
      if (
        list[this.currentIndex].url &&
        !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
          list[this.currentIndex].url
        )
      ) {
        return callback(new Error("请输入正确链接"));
      }
      callback();
    };
    // 检查发送方式
    const checkMethod = (rule, value, callback) => {
      const list = this.form.list;
      // 如果
      if (list[0].method === 1 && !list[0].day) {
        return callback(new Error("间隔发送天数不能为空"));
      }
      if (
        list[0].method === 1 &&
        !/^\+?[1-9]{1}[0-9]{0,2}\d{0,0}$/.test(list[0].day)
      ) {
        return callback(new Error("间隔天数范围为1-999整数"));
      }
      if (list[0].method === 2 && !list[0].week) {
        return callback(new Error("每周发送不能为空"));
      }
      if (list[0].method === 3 && !list[0].month) {
        return callback(new Error("每月发送不能为空"));
      }
      if (
        list[0].method === 4 &&
        (!list[0].many_weeks || !list[0].month_week)
      ) {
        return callback(new Error("每月发送不能为空"));
      }
      callback();
    };
    // 检查发送周期
    const checkCycle = (rule, value, callback) => {
      if (!this.form.start_cycle) {
        return callback(new Error("开始日期不能为空"));
      }
      if (
        new Date(this.form.end_cycle).getTime() <=
          new Date(this.form.start_cycle).getTime() &&
        this.form.end_cycle
      ) {
        return callback(new Error("结束日期不得小于开始日期"));
      }
      callback();
    };
    this.__messageType__ = __messageType__;
    return {
      timeTypeList: [
        { txt: "分钟", value: "minute" },
        { txt: "天", value: "day" },
        { txt: "月", value: "month" },
      ],
      saveLoading: false,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      pickerOptions1: {},
      many_weeks: [
        { txt: "第一个", value: 1 },
        { txt: "第二个", value: 2 },
        { txt: "第三个", value: 3 },
        { txt: "第四个", value: 4 },
      ],
      weekList: [
        {
          txt: "星期一",
          value: 1,
        },
        {
          txt: "星期二",
          value: 2,
        },
        {
          txt: "星期三",
          value: 3,
        },
        {
          txt: "星期四",
          value: 4,
        },
        {
          txt: "星期五",
          value: 5,
        },
        {
          txt: "星期六",
          value: 6,
        },
        {
          txt: "星期日",
          value: 7,
        },
      ],
      dayList: [
        { txt: "1号", value: 1 },
        { txt: "2号", value: 2 },
        { txt: "3号", value: 3 },
        { txt: "4号", value: 4 },
        { txt: "5号", value: 5 },
        { txt: "6号", value: 6 },
        { txt: "7号", value: 7 },
        { txt: "8号", value: 8 },
        { txt: "9号", value: 9 },
        { txt: "10号", value: 10 },
        { txt: "11号", value: 11 },
        { txt: "12号", value: 12 },
        { txt: "13号", value: 13 },
        { txt: "14号", value: 14 },
        { txt: "15号", value: 15 },
        { txt: "16号", value: 16 },
        { txt: "17号", value: 17 },
        { txt: "18号", value: 18 },
        { txt: "19号", value: 19 },
        { txt: "20号", value: 20 },
        { txt: "21号", value: 21 },
        { txt: "22号", value: 22 },
        { txt: "23号", value: 23 },
        { txt: "24号", value: 24 },
        { txt: "25号", value: 25 },
        { txt: "26号", value: 26 },
        { txt: "27号", value: 27 },
        { txt: "28号", value: 28 },
        { txt: "29号", value: 29 },
        { txt: "30号", value: 30 },
        { txt: "31号", value: 31 },
      ],
      __messageType__: {},
      parameterList: [], // 参数列表
      currentIndex: 0,
      // 表单监测规则
      rules: {
        title: [
          {
            required: true,
            message: "请输入消息名称",
            trigger: "blur",
          },
          {
            required: true,
            message: "请输入消息名称",
            trigger: "change",
          },
        ],
        start_cycle: [
          {
            validator: checkCycle,
            trigger: "blur",
          },
          {
            validator: checkCycle,
            trigger: "change",
          },
        ],

        list: [
          {
            url_type: [
              {
                validator: checkUrl,
                trigger: "blur",
              },
              {
                validator: checkUrl,
                trigger: "change",
              },
            ],
            day: [
              {
                validator: checkDay,
                trigger: "blur",
              },
              {
                validator: checkDay,
                trigger: "change",
              },
            ],
            month: [
              {
                validator: checkMonthDay,
                trigger: "blur",
              },
              {
                validator: checkMonthDay,
                trigger: "change",
              },
            ],
            data: {
              first: {
                value: [
                  {
                    required: true,
                    message: "请输入开始语",
                    trigger: "blur",
                  },
                  {
                    required: true,
                    message: "请输入开始语",
                    trigger: "change",
                  },
                ],
              },
              remark: {
                value: [
                  {
                    required: true,
                    message: "请输入结束语",
                    trigger: "blur",
                  },
                  {
                    required: true,
                    message: "请输入结束语",
                    trigger: "change",
                  },
                ],
              },
              keyword1: {
                value: [
                  {
                    required: true,
                    message: "请输入服务类型",
                    trigger: "blur",
                  },
                  {
                    required: true,
                    message: "请输入服务类型",
                    trigger: "change",
                  },
                ],
              },
              keyword2: {
                value: [
                  {
                    required: true,
                    message: "请输入服务状态",
                    trigger: "blur",
                  },
                  {
                    required: true,
                    message: "请输入服务状态",
                    trigger: "change",
                  },
                ],
              },
            },
            method: [
              {
                validator: checkMethod,
                trigger: "blur",
              },
              {
                validator: checkMethod,
                trigger: "change",
              },
            ],
          },
          {
            url_type: [
              {
                validator: checkUrl,
                trigger: "blur",
              },
              {
                validator: checkUrl,
                trigger: "change",
              },
            ],
            day: [
              {
                validator: checkDay,
                trigger: "blur",
              },
              {
                validator: checkDay,
                trigger: "change",
              },
            ],
            month: [
              {
                validator: checkMonthDay,
                trigger: "blur",
              },
              {
                validator: checkMonthDay,
                trigger: "change",
              },
            ],
            data: {
              first: {
                value: [
                  {
                    required: true,
                    message: "请输入开始语",
                    trigger: "blur",
                  },
                  {
                    required: true,
                    message: "请输入开始语",
                    trigger: "change",
                  },
                ],
              },
              remark: {
                value: [
                  {
                    required: true,
                    message: "请输入结束语",
                    trigger: "blur",
                  },
                  {
                    required: true,
                    message: "请输入结束语",
                    trigger: "change",
                  },
                ],
              },
              keyword1: {
                value: [
                  {
                    required: true,
                    message: "请输入服务类型",
                    trigger: "blur",
                  },
                  {
                    required: true,
                    message: "请输入服务类型",
                    trigger: "change",
                  },
                ],
              },
              keyword2: {
                value: [
                  {
                    required: true,
                    message: "请输入服务状态",
                    trigger: "blur",
                  },
                  {
                    required: true,
                    message: "请输入服务状态",
                    trigger: "change",
                  },
                ],
              },
            },
            method: [
              {
                validator: checkMethod,
                trigger: "blur",
              },
              {
                validator: checkMethod,
                trigger: "change",
              },
            ],
          },
          {
            url_type: [
              {
                validator: checkUrl,
                trigger: "blur",
              },
              {
                validator: checkUrl,
                trigger: "change",
              },
            ],
            day: [
              {
                validator: checkDay,
                trigger: "blur",
              },
              {
                validator: checkDay,
                trigger: "change",
              },
            ],
            month: [
              {
                validator: checkMonthDay,
                trigger: "blur",
              },
              {
                validator: checkMonthDay,
                trigger: "change",
              },
            ],
            data: {
              first: {
                value: [
                  {
                    required: true,
                    message: "请输入开始语",
                    trigger: "blur",
                  },
                  {
                    required: true,
                    message: "请输入开始语",
                    trigger: "change",
                  },
                ],
              },
              remark: {
                value: [
                  {
                    required: true,
                    message: "请输入结束语",
                    trigger: "blur",
                  },
                  {
                    required: true,
                    message: "请输入结束语",
                    trigger: "change",
                  },
                ],
              },
              keyword1: {
                value: [
                  {
                    required: true,
                    message: "请输入服务类型",
                    trigger: "blur",
                  },
                  {
                    required: true,
                    message: "请输入服务类型",
                    trigger: "change",
                  },
                ],
              },
              keyword2: {
                value: [
                  {
                    required: true,
                    message: "请输入服务状态",
                    trigger: "blur",
                  },
                  {
                    required: true,
                    message: "请输入服务状态",
                    trigger: "change",
                  },
                ],
              },
            },
            method: [
              {
                validator: checkMethod,
                trigger: "blur",
              },
              {
                validator: checkMethod,
                trigger: "change",
              },
            ],
          },
          {
            url_type: [
              {
                validator: checkUrl,
                trigger: "blur",
              },
              {
                validator: checkUrl,
                trigger: "change",
              },
            ],
            day: [
              {
                validator: checkDay,
                trigger: "blur",
              },
              {
                validator: checkDay,
                trigger: "change",
              },
            ],
            month: [
              {
                validator: checkMonthDay,
                trigger: "blur",
              },
              {
                validator: checkMonthDay,
                trigger: "change",
              },
            ],
            data: {
              first: {
                value: [
                  {
                    required: true,
                    message: "请输入开始语",
                    trigger: "blur",
                  },
                  {
                    required: true,
                    message: "请输入开始语",
                    trigger: "change",
                  },
                ],
              },
              remark: {
                value: [
                  {
                    required: true,
                    message: "请输入结束语",
                    trigger: "blur",
                  },
                  {
                    required: true,
                    message: "请输入结束语",
                    trigger: "change",
                  },
                ],
              },
              keyword1: {
                value: [
                  {
                    required: true,
                    message: "请输入服务类型",
                    trigger: "blur",
                  },
                  {
                    required: true,
                    message: "请输入服务类型",
                    trigger: "change",
                  },
                ],
              },
              keyword2: {
                value: [
                  {
                    required: true,
                    message: "请输入服务状态",
                    trigger: "blur",
                  },
                  {
                    required: true,
                    message: "请输入服务状态",
                    trigger: "change",
                  },
                ],
              },
            },
            method: [
              {
                validator: checkMethod,
                trigger: "blur",
              },
              {
                validator: checkMethod,
                trigger: "change",
              },
            ],
          },
          {
            url_type: [
              {
                validator: checkUrl,
                trigger: "blur",
              },
              {
                validator: checkUrl,
                trigger: "change",
              },
            ],
            day: [
              {
                validator: checkDay,
                trigger: "blur",
              },
              {
                validator: checkDay,
                trigger: "change",
              },
            ],
            month: [
              {
                validator: checkMonthDay,
                trigger: "blur",
              },
              {
                validator: checkMonthDay,
                trigger: "change",
              },
            ],
            data: {
              first: {
                value: [
                  {
                    required: true,
                    message: "请输入开始语",
                    trigger: "blur",
                  },
                  {
                    required: true,
                    message: "请输入开始语",
                    trigger: "change",
                  },
                ],
              },
              remark: {
                value: [
                  {
                    required: true,
                    message: "请输入结束语",
                    trigger: "blur",
                  },
                  {
                    required: true,
                    message: "请输入结束语",
                    trigger: "change",
                  },
                ],
              },
              keyword1: {
                value: [
                  {
                    required: true,
                    message: "请输入服务类型",
                    trigger: "blur",
                  },
                  {
                    required: true,
                    message: "请输入服务类型",
                    trigger: "change",
                  },
                ],
              },
              keyword2: {
                value: [
                  {
                    required: true,
                    message: "请输入服务状态",
                    trigger: "blur",
                  },
                  {
                    required: true,
                    message: "请输入服务状态",
                    trigger: "change",
                  },
                ],
              },
            },
            method: [
              {
                validator: checkMethod,
                trigger: "blur",
              },
              {
                validator: checkMethod,
                trigger: "change",
              },
            ],
          },
        ],
      },
      tabs: [{ id: 0 }],
      
      form: {
        status: 1,
        is_cycle: 0, // 是否循环发送，默认否
        send_object: 1,
        list: [
          {
            time: "",
            timeType: "",
            timeValue: "",
            wechat_message_scenario_id: 0,
            url_type: 1,
            method: 0,
            url: "", // 链接
            data: {
              first: {
                value: "",
                color: "#000000",
              },
              keyword1: {
                value: "",
                color: "#000000",
              },
              keyword2: {
                value: "",
                color: "#000000",
              },
              keyword3: {
                value: "{{推送时间}}",
                color: "#000000",
              },
              keyword4: {
                value: "",
                color: "#000000",
              },
              keyword5: {
                value: "",
                color: "#000000",
              },
              remark: {
                value: "",
                color: "#000000",
              },
            }, // 开始语等数据
          },
        ],
      },
      iphoneImg: require("@/assets/images/marketing/message/iphone.png"),
    };
  },
  props: {
    row: {
      type: Object,
      default() {
        return {};
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {
    this.getParameter();
    if (this.row.wechat_message_scenario_id) {
      this.toRead();
    }
  },
  methods: {
    handleChange(val, index) {
      let obj = this.form.list[index];
      if (val === "minute") {
        obj.time = "";
        obj.day = "";
        obj.month = "";
      }
      if (val === "day") {
        obj.minute = "";
        obj.month = "";
      }
      if (val === "month") {
        obj.minute = "";
        obj.day = "";
      }
      if (obj.timeValue && obj.timeType) {
        obj[obj.timeType] = obj.timeValue;
      }
    },
    // 输入分天月的输入框
    handleInput(index) {
      let obj = this.form.list[index];
      if (obj.timeValue && obj.timeType) {
        obj[obj.timeType] = obj.timeValue;
      }
    },
    // 查看模板消息
    toRead() {
      readReq({
        wechat_config_id: this.item.wechat_config_id,
        wechat_message_scenario_id: this.row.wechat_message_scenario_id,
      }).then((res) => {
        if (res.code === 1) {
          this.form = res.data;
          this.form.list = this.form.list.map((item) => {
            let obj = { ...item };
            // 如果是分钟
            if (obj.minute) {
              obj.timeType = "minute";
              obj.timeValue = obj.minute;
            }
            // 如果是天
            if (obj.day) {
              obj.timeType = "day";
              obj.timeValue = obj.day;
            }
            // 如果是月
            if (obj.month) {
              obj.timeType = "month";
              obj.timeValue = obj.month;
            }
            if (!obj.timeValue) {
              obj.timeValue = "";
            }
            if (!obj.timeType) {
              obj.timeType = "";
            }
            return obj;
          });
          this.tabs = this.form.list.map((_, index) => {
            return { id: index };
          });
        }
      });
    },
    // 保存表格
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // tabs的校验
          let errorFlag;
          if (this.row.type === 2 || this.row.type === 7) {
            const checkTabs = [...this.form.list];
            for (let i = 0; i < checkTabs.length; i += 1) {
              const item = checkTabs[i];
              if (!item.timeValue || !item.timeType) {
                this.$message.warning(`第${i + 1}个推送消息时间不完善`);
                errorFlag = true;
                break;
              }
              if (
                (item.timeType === "day" || item.timeType === "month") &&
                !item.time
              ) {
                this.$message.warning(`第${i + 1}个推送消息时间不完善`);
                errorFlag = true;
                break;
              }
              if (Number(item.timeValue) > 9999 || Number(item.timeValue) < 0 || ('' + item.timeValue).split('.').length >= 2) {
                this.$message.warning(`第${i + 1}个推送消息时间必须为1-9999正整数`);
                errorFlag = true;
                break;
              }
            }
          }
          if (errorFlag) {
            return;
          }
          this.saveLoading = true;
          const list = this.form.list.map((item) => {
            let obj = { ...item };
            delete obj.timeType;
            delete obj.timeValue;
            return obj;
          });
          const newForm = {
            ...this.form,
            list,
          };
          saveReq({
            type: this.row.type,
            wechat_config_id: this.item.wechat_config_id,
            ...newForm,
          })
            .then((res) => {
              this.saveLoading = false;
              if (res.code === 1) {
                this.$message.success("新增成功");
                this.$emit("onBack", "getList");
              }
            })
            .catch(() => {
              this.saveLoading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 详情链接选择
    changeRadio(index) {
      let obj = this.form.list[index];
      if (!obj.url_type) {
        obj.url = "";
      }
    },
    // 插入参数选择
    async handleChangeSelect(index, key, id) {
      const target = document.getElementById([id]);
      let obj = this.form.list[index];
      const start = obj.data[key].value.slice(0, target.selectionStart);
      const end = obj.data[key].value.slice(target.selectionStart, obj.data[key].value.length);
      obj.data[key].value = start + obj.data[key].params + end;
      // 清空
      obj.data[key].params = "";
      await this.$nextTick();
      target.focus();
    },
    // 更改方式
    handleRadio(val) {
      const form = { ...this.form };
      if (val === 0) {
        form.is_cycle = 0;
      } else {
        form.is_cycle = 1;
      }
      this.form = form;
      console.log(this.form);
    },
    // 获取参数
    getParameter() {
      getParameterReq().then((res) => {
        if (res.code === 1) {
          this.parameterList = res.data;
        }
      });
    },
    handleClick(index) {
      this.currentIndex = index;
    },
    handlDele(index) {
      this.tabs.forEach((_, _index) => {
        if (_index === index) {
          this.tabs.splice(_index, 1);
        }
      });
      this.form.list.forEach((_, _index) => {
        if (_index === index) {
          this.form.list.splice(_index, 1);
        }
      });
      if (this.currentIndex === index) {
        this.currentIndex = 0;
        console.log("触发");
      }
    },
    handleAdd() {
      this.tabs.push({
        id: this.tabs[this.tabs.length - 1].id + 1,
      });
      this.form.list.push({
        time: "",
        timeType: "",
        timeValue: "",
        wechat_message_scenario_id: 0,
        url_type: 1,
        method: 0,
        url: "", // 链接
        data: {
          first: {
            value: "",
            color: "",
          },
          keyword1: {
            value: "",
            color: "#000000",
          },
          keyword2: {
            value: "",
            color: "#000000",
          },
          keyword3: {
            value: "{{推送时间}}",
            color: "#000000",
          },
          remark: {
            value: "",
            color: "",
          },
        }, // 开始语等数据
      });
      this.currentIndex = this.tabs.length - 1;
    },
    goBack() {
      this.$emit("onBack");
    },
  },
};
</script>

<style lang="less" scoped>
.radio-box {
  height: 40px;
  /deep/ .el-input__inner {
    height: 36px;
  }
}
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type="number"] {
  -moz-appearance: textfield !important;
}
.tabs {
  position: relative;
  height: 118px;
  left: 40px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 12px;
  box-sizing: border-box;
  .plus {
    width: 160px;
    height: 106px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #909399;
    border: 1px solid #ddd;
    border-bottom: none;
    border-radius: 6px 6px 0 0;
    background: rgba(240, 241, 245, 1);
    flex-shrink: 0;
    cursor: pointer;
    .el-icon-plus {
      font-size: 24px;
      font-weight: bold;
      color: #909399;
      margin-top: 15px;
    }
  }
  .tab {
    width: 278px;
    height: 106px;
    border-radius: 6px 6px 0 0;
    border: 1px solid #ddd;
    border-bottom: none;
    background: rgba(240, 241, 245, 1);
    padding: 14px 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #565656;
    box-sizing: border-box;
    margin-right: 15px;
    cursor: pointer;
    position: relative;
    .el-icon-error {
      font-size: 24px;
      position: absolute;
      top: -12px;
      right: -12px;
      color: #909399;
      z-index: 99;
    }
    &-active {
      background: white;
    }
  }
}
.border {
  border: 1px solid #ddd;
  padding-top: 10px;
  margin-left: 140px;
  width: 92%;
  margin-bottom: 15px;
}
.btns {
  display: flex;
  justify-content: center;
  background: white;
  padding: 10px 0;
  position: fixed;
  left: 510px;
  right: 0;
  bottom: 0;
}
.color {
  width: 24px !important;
  height: 28px;
  /deep/ .el-input__inner {
    border: none;
    width: 100%;
    height: 100%;
    padding: 0;
  }
}
.right {
  background: white;
  overflow-y: auto;
  flex: 1;
  height: calc(100vh - 210px);
  margin-left: 10px;
  padding: 40px 0px;
  box-sizing: border-box;
  position: relative;
  /deep/ .el-form-item__error {
    margin-left: 24px;
  }
  &-item {
    display: flex;
    align-items: center;
    height: 40px;
    margin-left: 40px;
    cursor: pointer;
    .round {
      border: 1px solid #dcdfe6;
      border-radius: 100%;
      width: 14px;
      height: 14px;
      background-color: #fff;
      cursor: pointer;
      box-sizing: border-box;
      position: relative;
    }
    .active {
      border-color: #409eff;
      background: #409eff;
      &::after {
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background-color: #fff;
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.15s ease-in;
        transform: translate(-50%, -50%) scale(1);
      }
    }
    .round-txt {
      font-size: 14px;
      padding-left: 10px;
      color: #606266;
      /deep/ .el-input__inner {
        height: 36px;
        text-align: center;
      }
    }
    .active-txt {
      color: #409eff;
    }
  }
}
/deep/ .el-form-item__label {
  margin-right: 24px;
  color: #909399;
}
.link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item {
  background: white;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 30px;
  &-title {
    font-weight: 700;
    color: #565656;
  }
  &-content {
    color: #909399;
    margin-top: 5px;
  }
  .model {
    margin-bottom: 15px;
    .flex {
      display: flex;
      line-height: 25px;
      .model-left {
        color: #909399;
        flex-shrink: 0;
        width: 65px;
      }
      .model-right {
        color: #565656;
        margin-left: 40px;
      }
    }
  }
}
.phone {
  width: 350px;
  min-height: 700px;
  height: 100%;
  background-size: 100% 100%;
  margin: 0 auto;
  padding: 100px 20px 40px 20px;
  box-sizing: border-box;
}
.box {
  display: flex;
}

.img {
  height: 100%;
  margin: 0 auto;
  display: block;
}
.left {
  background: white;
  width: 410px;
  height: calc(100vh - 210px);
  padding: 20px;
  box-sizing: border-box;
}
.title {
  background: white;
  height: 48px;
  line-height: 48px;
  text-indent: 32px;
  font-weight: 700;
  font-size: 16px;
  border-bottom: 1px solid #ddd;
  color: #565656;
}
.main {
  margin-top: 10px;
}
/deep/ .el-page-header__title {
  color: #565656;
}
/deep/ .el-page-header__content {
  font-size: 16px;
  color: #565656;
}
.top {
  background: white;
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 32px;
}
</style>
